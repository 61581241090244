<template>
  <div class="q-pa-md">
    <h1 class="c8" id="h.y58b066hwyas">
      <span class="c9 c14"
        >CNFT Terms &amp; Conditions for Purchasers of NFTs</span
      >
    </h1>
    <p class="c1">
      <span class="c2 c0"
        >By purchasing an NFT (&ldquo;Crypto Asset&rdquo; as defined in the
        platform&rsquo;s Terms of Service) from CNFT, Inc. (&ldquo;CNFT&rdquo;)
        including any artwork, graphics, images, designs, logos, drawings,
        photographs, text, taglines, video or audio recording (collectively the
        &ldquo;Art&rdquo;), you acknowledge and agree to the following terms and
        conditions (&ldquo;Terms&rdquo;):</span
      >
    </p>
    <h2 class="c7" id="h.qf0kk4obz991">
      <span class="c3">1. WALLET SET-UP</span>
    </h2>
    <p class="c1">
      <span class="c0 c6"
        >Payment will be collected through Stripe, a third party vendor that
        enables easy collection of payment via credit cards. No cryptocurrency
        is required to purchase a NFT from CNFT.us. &nbsp;When you sign up an
        account on CNFT.us, a Polygon-based Wallet will be automatically created
        and You can use Polygon Tokens (MATIC) for all Transactions on CNFT.us
        marketplace. &nbsp;</span
      >
    </p>
    <p class="c1 c11">
      <span class="c3"
        >2. &nbsp;OWNERSHIP, LICENSE, AND OWNERSHIP RESTRICTIONS</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >For the purposes of this Section 2, the following capitalized terms
        will have the following meanings: &nbsp;&ldquo;Own&rdquo; means, with
        respect to a NFT, a NFT that you have purchased or otherwise rightfully
        acquired on this website. &ldquo;Purchased NFT&rdquo; means a NFT that
        you Own. &ldquo;Third Party IP&rdquo; means any third party patent
        rights (including, without limitation, patent applications and
        disclosures), copyrights, trade secrets, trademarks, know-how or any
        other intellectual property rights recognized in any country or
        jurisdiction in the world.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(i) Ownership of NFT. &nbsp;Because each NFT is a Non-Fungible Token,
        when you purchase a NFT in accordance with these Terms, you own the
        underlying NFT completely (but shall have no ownership rights in the
        underlying Art other than incorporated in the Purchased NFT).</span
      >
    </p>
    <p class="c1">
      <span class="c0 c2"
        >(ii) User License to Art. &nbsp;Subject to your continued compliance
        with these Terms, we grant you a worldwide, non-exclusive,
        non-transferable, royalty-free license to use, copy, and display the Art
        for your Purchased NFTs, solely for the following purposes: (a) for your
        own personal, non-commercial use; (b) as part of a marketplace that
        permits the purchase and sale of your Purchased NFTs, provided that the
        marketplace cryptographically verifies each NFT owner&rsquo;s rights to
        display the Art for their Purchased NFT to ensure that only the actual
        owner can display the Art; or (c) as part of a third party website or
        application that permits the inclusion, involvement, or participation of
        your Purchased NFT, provided that the website/application
        cryptographically verifies each NFT&rsquo;s owner&rsquo;s rights to
        display the Art for their Purchased NFT to ensure that only the actual
        owner can display the Art, and provided that the Art is no longer
        visible once the owner of the Purchased NFT leaves the
        website/application.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(iii) Restrictions on Ownership. &nbsp;You agree that you may not, nor
        permit any third party to do or attempt to do any of the foregoing
        without our (or, as applicable, our licensors&rsquo;) express prior
        written consent in each case: (a) modify the Art for your Purchased NFT
        in any way, including, without limitation, the shapes, designs,
        drawings, attributes, or color schemes; (b) use the Art for your
        Purchased NFT to advertise, market, or sell any third party product or
        service; (c) use the Art for your Purchased NFT in connection with
        images, videos, or other forms of media that depict hatred, intolerance,
        violence, cruelty, or anything else that could reasonably be found to
        constitute hate speech or otherwise infringe upon the rights of others;
        (d) use the Art for your Purchased NFT in movies, videos, or any other
        forms of media, except to the limited extent that such use is expressly
        permitted in these Terms or solely for your own personal, non-commercial
        use; (e) sell, distribute for commercial gain (including, without
        limitation, giving away in the hopes of eventual commercial gain), or
        otherwise commercialize merchandise that includes, contains, or consists
        of the Art for your Purchased NFT; (f) attempt to trademark, copyright,
        or otherwise acquire additional intellectual property rights in or to
        the Art for your Purchased NFT; or (g) otherwise utilize the Art for
        your Purchased NFT for your or any third party&rsquo;s commercial
        benefit.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(vi) Sale or Transfer of a NFT. You shall have the limited right to
        transfer a NFT including your license to the Art associated with a NFT,
        subject to the following: (i) the transferee must accept all of these
        Terms; (ii) you shall have not, prior to the transfer, breached these
        Terms or had your use or access to this website terminated; and (iii)
        CNFT shall be entitled to receive fifteenth percent (15%) of the gross
        amounts paid by the transferee (and each subsequent transferee) for the
        NFT, in perpetuity, it being understood and agreed that if the
        applicable marketplace, for any reason, shall fail to withhold secondary
        market fees to pay CNFT its share, you shall be remain liable to pay
        CNFT its share hereunder. <br />
        Refund Policy: <br />
        All purchases of NFT products as well as associated charges, are
        non-refundable. This no-refund policy shall apply at all times
        regardless of Your decision to terminate usage of the NFT products, any
        disruption to the operations of any components of NFT products, or any
        other reasons.
      </span>
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(v) Other Terms of License. &nbsp;The license granted in Section 2(ii)
        above applies only to the extent that you continue to Own the applicable
        Purchased NFT. If at any time you sell, swap, donate, give away,
        transfer, or otherwise dispose of your Purchased NFT for any reason, the
        license granted in Section 2(ii) will immediately expire with respect to
        that NFT without the requirement of notice, and you will have no further
        rights in or to the Art for that NFT.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(vi) Sale or Transfer of a NFT. You shall have the limited right to
        transfer a NFT including your license to the Art associated with a NFT,
        subject to the following: (i) &nbsp;the transferee must accept all of
        these Terms; (ii) you shall have not, prior to the transfer, breached
        these Terms or had your use or access to this website terminated; and
        (iii) CNFT shall be entitled to receive ten percent (10%) of the gross
        amounts paid by the transferee (and each subsequent transferee) for the
        NFT, in perpetuity, it being understood and agreed that if the
        applicable marketplace, for any reason, shall fail to withhold secondary
        market fees to pay CNFT its share, you shall be remain liable to pay
        CNFT its share hereunder.</span
      >
    </p>
    <h2 class="c7" id="h.abaq7tmf0mpw">
      <span class="c3">3. ASSUMPTION OF RISK</span>
    </h2>
    <p class="c1">
      <span class="c2 c0"
        >(i) Value and Volatility. The prices of collectible blockchain assets
        are extremely volatile and subjective and collectible blockchain assets
        have no inherent or intrinsic value. Fluctuations in the price of other
        digital assets could materially and adversely affect the value of your
        NFTs, which may also be subject to significant price volatility. Each
        NFT has no inherent or intrinsic value. We cannot guarantee that any
        NFTs purchased will retain their original value, as the value of
        collectibles is inherently subjective and factors occurring off this
        website may materially impact the value and desirability of any
        particular NFT.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(ii) Tax Calculations. You are solely responsible for determining what,
        if any, taxes apply to your NFT-related transactions. We are not
        responsible for determining the taxes that apply to your transactions
        with respect to any NFT.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(iii) Use of Blockchain. This website does not store, send, or receive
        NFTs. This is because NFTs exist only by virtue of the ownership record
        maintained on the website&rsquo;s supporting blockchain. Any transfer of
        NFTs occurs within the supporting blockchain network, and not on this
        website.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >(iv) Inherent Risks with Internet Currency. There are risks associated
        with using an Internet-based currency, including, but not limited to,
        the risk of hardware, software and Internet connections, the risk of
        malicious software introduction, and the risk that third parties may
        obtain unauthorized access to information stored within your electronic
        wallet. You accept and acknowledge that we will not be responsible for
        any communication failures, disruptions, errors, distortions or delays
        you may experience when using the Flow Network, however caused.</span
      >
    </p>
    <p class="c1">
      <span class="c2 c0"
        >v) Regulatory Uncertainty. The regulatory regime governing blockchain
        technologies, cryptocurrencies and tokens is uncertain, and new
        regulations or policies may materially adversely affect the potential
        utility or value of your NFTs.</span
      >
    </p>
    <h2 class="c7">
      <span class="c3">4. DISPUTE RESOLUTION</span>
    </h2>
    <p class="c1">
      <span class="c2 c0">
        (i). Please read the following arbitration agreement in this Section (“Arbitration Agreement”) carefully. It requires you to arbitrate disputes with CNFT.us and limits the manner in which you can seek relief from us. <br>

(ii). Applicability of Arbitration Agreement. You agree that any dispute or claim relating in any way to your access or use of the Service, to any products sold or distributed through the Service, or to any aspect of your relationship with CNFT.us, will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify; and (2) you or CNFT.us may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents). <br>

(iii). Arbitration Rules and Forum. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to Attention: Legal Department, 228 Park Ave S, #22014, New York, NY 10003. The arbitration will be conducted by JAMS, an established alternative dispute resolution provider. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules are also available at jamsadr.com or by calling JAMS at 800-352- 5267. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, CNFT.us will pay them for you. In addition, CNFT.us will reimburse all such JAMS’s filing, administrative, hearing and/or other fees for claims totaling less than $10,000 unless the arbitrator determines the claims are frivolous. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the country where you live or at another mutually agreed location. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. <br>

(iv). Authority of Arbitrator. The arbitrator shall have exclusive authority to (a) determine the scope and enforceability of this Arbitration Agreement and (b) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and CNFT.us. The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to grant motions dispositive of all or part of any claim. The arbitrator shall have the authority to award monetary damages and to grant any non- monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and the Terms (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. <br>
      </span>
    </p>
    <h2 class="c7">
      <span class="c3">5. BREACH OF THESE TERMS</span>
    </h2>
    <p class="c1">
      <span class="c2 c0"
        >In the event you violate any of these Terms, you agree that in addition
        to all of CNFT&rsquo;s rights and remedies for violating CNFT&rsquo;s
        intellectual property and other rights, such violation shall constitute
        a breach of contract, for which CNFT shall have the right to pursue such
        additional rights and remedies as may be available to CNFT for breach of
        contract, including, without limitation, monetary damages and injunctive
        relief.</span
      >
    </p>
    <p class="c1">
      <span class="c0">Do you have feedback? </span
      ><span>Let us know and send your feedback at </span
      ><span class="c0">&nbsp;</span
      ><span class="c13"
        ><a class="c10" href="mailto:cnft.co@gmail.com"
          >cnft.co@gmail.com</a
        ></span
      ><span class="c0">&nbsp;</span>
    </p>
    <p class="c1">
      <span class="c0"
        >Have questions? Do you have feedback? Please let us know send your
        questions at </span
      ><span class="c13"
        ><a class="c10" href="mailto:cnft.co@gmail.com"
          >cnft.co@gmail.com</a
        ></span
      ><span class="c0">&nbsp;</span>
    </p>
    <p class="c12"><span class="c2 c9"></span></p>
  </div>
</template>
<style lang="sass" scoped>
.faq
  max-width: 80%
  h1
    font-size: 2rem
  h2
    font-size: 1.5rem
</style>

<script>
export default {
  name: "Terms",
};
</script>
